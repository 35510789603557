import React from "react";
import styles from "./Footer.module.css";
import footerLogo from "../../Assets/Footer/GurukulamLogoWhite.svg";
import youtubeLogo from "../../Assets/Footer/yt-logo.svg";
import instaLogo from "../../Assets/Footer/insta-logo.svg";
import linkedinLogo from "../../Assets/Footer/linkedin-logo.svg";
import location from "../../Assets/location.svg";
import phone from "../../Assets/phone.svg";
import EnquiryForm from "../Enquiry Page/EnquiryForm"; // Adjust the path as per your structure

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftFooter}>
          <div className={styles.footerLogoBox}>
            <img src={footerLogo} alt="Logo" />
          </div>

          <div className={styles.addressContainer}>
            <div className={styles.locationBox}>
              <img src={location} alt="Location" />
              <div>
                PLOT NO-MI 5, MI 6, MI 7,8 MAUZA GAURI PRAGANA-DHUS,
                TEH-MUGHALSARAI(PT DDU), DIST-CHANDAULI-232101
              </div>
            </div>
            <div className={styles.phoneBox}>
              <img src={phone} alt="Phone" />
              <a href="tel:+917314621009" className={styles.phoneText}>
                +91 7314621009
              </a>
            </div>
          </div>

          <div className={styles.container}>
            <h3 className={styles.heading}>Connect with us on</h3>
            <div className={styles.iconContainer}>
              <a
                href="http://www.youtube.com/@PWGurukulam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.iconWrapper}>
                  <img
                    src={youtubeLogo}
                    alt="YouTube Logo"
                    width={36}
                    height={36}
                  />
                </div>
              </a>
              <a
                href="https://www.instagram.com/the.gurukulamschool/?utm_source=ig_web_button_share_sheet"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.iconWrapper}>
                  <img
                    src={instaLogo}
                    alt="Instagram Logo"
                    width={36}
                    height={36}
                  />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/gurukulam-school-by-pw/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.iconWrapper}>
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn Logo"
                    width={36}
                    height={36}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className={styles.rightFooter}>
          <EnquiryForm />
        </div>
      </div>

      <div style={{ borderTop: "1px solid white", width: "95%" }}></div>
      <div className={styles.copyRightDiv}>
        <p>© Copyright 2024, PW Gurukulam School.</p>
      </div>
    </div>
  );
};

export default Footer;
