import React, { forwardRef, useState, useEffect } from "react";
import styles from "./EnquiryPage.module.css";
import indianFlag from "../../Assets/indianFlag.png";
import InputField from "../InputText/InputField";
import axios from "axios";
import toast from "react-hot-toast";

const EnquiryPage = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    studentName: "",
    studentEmail: "",
    studentPhoneNumber: "",
    guardianName: "",
    interestedGrade: "",
  });

  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch grades for admission
  const fetchClassData = async () => {
    try {
      const response = await axios.get(
        `https://pwggurgaon.accevate.com/api_query/class_api?school_name=PWGVNS`
      );
      const data = response.data.message;

      const formattedClasses = Object.entries(data).map(([key, label]) => ({
        label: label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(),
        value: key,
      }));
      setClasses(formattedClasses);
    } catch (error) {
      console.error("Error fetching class data:", error);
      toast.error("Failed to load grades. Please try again.");
    }
  };

  useEffect(() => {
    fetchClassData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.interestedGrade === "placeholder") {
      toast.error("Please select a grade to proceed!");
      return;
    }

    if (formData.studentPhoneNumber.toString().length !== 10) {
      toast.error("Enter a valid mobile number");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "https://pwggurgaon.accevate.com/api_query/",
        {
          name: formData.studentName,
          email: formData.studentEmail,
          mobileNumber: formData.studentPhoneNumber,
          guardianName: formData.guardianName,
          grade: formData.interestedGrade,
          school: "PWGVNS",
          center: "Varanasi",
        }
      );

      if (response.data.response === "200") {
        toast.success("Your request has been submitted successfully.");
        setFormData({
          studentName: "",
          studentEmail: "",
          studentPhoneNumber: "",
          guardianName: "",
          interestedGrade: "",
        });
      } else {
        throw new Error("Unexpected response");
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.mainContainer} ref={ref}>
      <div className={styles.contentContainer}>
        <div className={styles.leftContentBox}>
          <div className={styles.brandDesc}>
            Where We Build Character, Values & Foster{" "}
            <span>Academic Growth</span>
          </div>
          <div style={{ height: "10px" }}></div>
          <button className={styles.admissionButton}>
            VARANASI ADMISSION OPEN FOR SESSION 2024-25
          </button>
          <div style={{ height: "10px" }}></div>
          <div className={styles.classText}>Pre-Nursery to Grade IX and XI</div>
        </div>
        <div className={styles.rightContentBox}>
          <div className={styles.enquiryText}>ENQUIRE NOW</div>
          <br />
          <form onSubmit={handleSubmit}>
            <InputField
              onChange={(e) =>
                setFormData({ ...formData, studentName: e.target.value })
              }
              placeholder="Enter Student Name*"
              required={true}
              type="text"
              value={formData.studentName}
            />
            <InputField
              onChange={(e) =>
                setFormData({ ...formData, studentEmail: e.target.value })
              }
              placeholder="Enter Email Address"
              required={true}
              type="email"
              value={formData.studentEmail}
            />
            <InputField
              onChange={(e) =>
                setFormData({ ...formData, studentPhoneNumber: e.target.value })
              }
              placeholder="Enter Your Mobile Number*"
              required={true}
              type="number"
              value={formData.studentPhoneNumber}
            />
            <InputField
              onChange={(e) =>
                setFormData({ ...formData, guardianName: e.target.value })
              }
              placeholder="Enter Guardian Name*"
              required={true}
              type="text"
              value={formData.guardianName}
            />
            <DropDown
              onChange={(e) =>
                setFormData({ ...formData, interestedGrade: e.target.value })
              }
              placeholder="Select Interested Grade*"
              value={formData.interestedGrade}
              required
              options={classes}
            />
            <button
              className={styles.submitButtonEnquiry}
              type="submit"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit Now"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
});

export default EnquiryPage;

const DropDown = ({ onChange, placeholder, value, required, options }) => {
  return (
    <div className={styles.dropdownForGrade}>
      <select value={value} onChange={onChange} required={required}>
        <option value="placeholder">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
