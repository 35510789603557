import React, { useRef } from "react";
import TopNavbar from "../../Components/TopNavbar/TopNavbar";
import Advertisement from "../../Components/Advertisement/Advertisement";
import EnquiryPage from "../../Components/Enquiry Page/EnquiryPage";
import WhoWeAre from "../../Components/Who are we/WhoWeAre";
import SchoolDetailed from "../../Components/SchoolDetailed/SchoolDetailed";
import SchoolOverView from "../../Components/SchoolOverView/SchoolOverView";
import DetailsWithImages from "../../Components/DetailsWithImages/DetailsWithImages";
import SalePage from "../../Components/SalePage/SalePage";
import Footer from "../../Components/Footer/Footer";
import PWHostel from "../../Components/PWHostel/PWHostel";
import FooterComponent from "../../Components/Footer/Footer";

const LandingPage = () => {
  const sectionOneRef = useRef(null);

  const handleScrollToSectionOne = () => {
    sectionOneRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <TopNavbar />
      <Advertisement />
      <EnquiryPage ref={sectionOneRef} />
      <WhoWeAre />
      <SchoolDetailed handleScrollToSectionOne={handleScrollToSectionOne} />
      <SchoolOverView />
      <DetailsWithImages />
      <PWHostel handleScrollToSectionOne={handleScrollToSectionOne} />
      <SalePage handleScrollToSectionOne={handleScrollToSectionOne} />
      <FooterComponent />
    </div>
  );
};

export default LandingPage;
